import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import fusionSupportProgramImg from "../../../../assets/image/fusionSupportProgram_img.jpg";
const FusionSupportProgram = () => {
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };
  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}산학융합지구조성사업
            </div>
            <div className="banner_main_text">산학융합지구조성사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">산학융합지구조성사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/chungnamInnovationProject")}
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/localIndustryTalentDevelopment")}
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div className="navi_box formation" onClick={() => handleSelect(2)}>
              <div className="navi_main_text">
                충남당진산학융합지구 기업밀착형 산학융합촉진지원사업
              </div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  충남당진산학융합지구 기업밀착형 산학융합촉진지원사업
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sub_main_area founded "> */}
        <div className="sub_main_area temporary">
          <div className="sub_main_back image_box">
            <div className="fusionSupportProgram">
              <div className="fusionSupportProgram_img"></div>
              {/* <img src={fusionSupportProgramImg}></img> */}
            </div>
            {/* <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업목적</div>
              </div>
              <div className="founded_img_box formation_one">
                <div className="founded_img formation_one"></div>
              </div>
              <div className="founded_img_box formation_two">
                <div className="founded_img formation_two"></div>
              </div>
            </div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="founded_text_box formation">
                <div className="founded_text_row">
                  <div className="text_title">산학융합 거점 공간 조성 </div>
                  <div className="text_box">
                    <div className="text_row">
                      {" "}
                      - 산업단지 캠퍼스 및 기업연구관 조성{" "}
                    </div>
                  </div>
                </div>
                <div className="founded_text_row">
                  <div className="text_title">근로자 평생학습 프로그램 </div>
                  <div className="text_box">
                    <div className="text_row">
                      - 근로자가 일하며 배울 수 있는 학위, 비학위 과정 운영{" "}
                    </div>
                    <div className="text_row">
                      {" "}
                      - 마이스터고, 특성화고 학생이 산업단지 기업, 대학으로 취업
                      및 진학 연계{" "}
                    </div>
                  </div>
                </div>
                <div className="founded_text_row">
                  <div className="text_title">R&D 연계 맞춤형 교육 </div>
                  <div className="text_box">
                    <div className="text_row">
                      - 현장맞춤형 기술/연구 인력 양성 및 취업/창업 연계지원{" "}
                    </div>
                  </div>
                </div>
                <div className="founded_text_row">
                  <div className="text_title">중소기업 연구개발 역량 강화</div>
                  <div className="text_box">
                    <div className="text_row">
                      {" "}
                      - 기업연구관 내에 중소, 중견기업 연구소 설치 및 R&D지원{" "}
                    </div>
                    <div className="text_row">
                      - 대기업, 중소기업 협력 및 대학 연계형 교육 및 기술지원{" "}
                    </div>
                  </div>
                </div>
                <div className="founded_text_row">
                  <div className="text_title">산학융합형 교육 시스템 도입 </div>
                  <div className="text_box">
                    <div className="text_row">
                      - 산학융합형 학위 및 교과 과정 개편{" "}
                    </div>
                    <div className="text_row">
                      - 현장맞춤형 산업인력 양성 모델 반영
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="founded_img_box formation_three">
                <div className="founded_img formation_three"></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FusionSupportProgram;
